<template>
    <div class="lct-content">
        <app-menu @onActive="onActive" @onTagsActive="onTagsActive" :isLive="true" :isActciles="true"></app-menu>
        <div class="lct-main">
            <live-list-item v-for="(item,index) in lists" :item="item" @click.native="toPath(item)" :key="item.id"></live-list-item>
            <no-data v-if="lists.length<=0"/>
        </div>
        <div class="pagination" v-if="lists.length>0">
            <el-pagination
                    background
                    layout="prev, pager, next, jumper"
                    :page-count="total"
                    @current-change="currentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import Search from '@/utils/search'
    export default {
        data() {
            return {
                current: 1,
                total: 0,
                class: null,
                tags: [],
                keyword: this.$route.query.keyword,
                lists: []
            }
        },
        beforeDestroy() {
            Search.$off("search");
        },
        mounted() {
            Search.$on('search',data => {
                // console.log(data)   //{show:true}
                this.keyword = data.keyword;
                this.page = 1;
                this.getList();
            })
            this.getList();
        },
        methods: {
            onActive(i) {
                this.class = i
                this.current = 1;
                this.getList();
            },
            onTagsActive(tags) {
                this.tags = tags;
                this.current = 1;
                this.getList();
            },
            getList() { // 列表
                if(this.$route.query.tag_id) {
                    this.tags.push(this.$route.query.tag_id)
                }
                this.$axios.post(this.apiUrl.index2, {
                    class: this.class,
                    tags: this.tags.join(','),
                    keyword: this.keyword,
                    page: this.current,
                    pagesize: 20
                }).then(res => {
                    this.lists = res.data.lists;
                    this.total = res.data.allpage;
                })
            },
            currentChange(e) {
                document.documentElement.scrollTop = 0
                this.current = e;
                this.getList();
            },
            toPath(item) {
				if(item.live_state==3) return this.$message({ message: item.live_title, type: 'warning'});
                let url = (item.live_state == 1 || item.live_state == 2 || item.live_state == 3)?`/seminars/${item.id}/detail`:`/seminars/${item.vid || item.id}/details?title=${encodeURIComponent(item.title)}`;
                this.$router.push({path: url})
            }
        }
    }
</script>
